<template>
  <div class="page-container">
    <div class="content-wrap">
    <TopNavBar/>
    <br>
    <b-container fluid="true">
      <b-row>
        <b-col class="col-0 col-sm-0 col-md-1 col-lg-2"></b-col>
        <b-col class="col-12 col-sm-12 col-md-10 col-lg-8">
          <b-card
            class="mt-5"
            title="Create Team"
          >

              <div v-if="isTeamAdmin">
                <b-alert show variant="info">
                  {{$t('You are already admin and member of a team, so you can\'t create an new team atm. Create a new account if you want to create a new team. Otherwise go to your team dashboard (https://genderapp.org/team-dashboard) to see your current team.')}}
                </b-alert>
              </div>
            <b-form @reset="onReset" @submit="onSubmit" v-if="show">
              <b-row>
                <!--Team name-->
                <b-col md="12" sm="12">
                  <b-form-group
                    id="teamNameLabel"
                    :label="$t('Team Name:')"
                    label-for="teamName"
                  >
                    <b-form-input
                      id="teamName"
                      :placeholder="$t('Team name')"
                      type="text"
                      v-model="teamName"
                    ></b-form-input>
                  </b-form-group>
                  <b-card class="bg-active mt-5">
                    <b-card-header class="bg-info text-light mb-4">
                      {{$t('Optional: Details for invoice')}}
                    </b-card-header>
                    <b-form-group
                      id="companyName"
                      :label="$t('Company name:')"
                      label-for="companyName"
                    >
                      <b-form-input
                        id="companyNameLabel"
                        :placeholder="$t('Company name')"
                        type="text"
                        v-model="companyName"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="invoiceRecipientLabel"
                      :label="$t('Invoice Recipient:')"
                      label-for="invoiceRecipient"
                    >
                      <b-form-input
                        id="invoiceRecipient"
                        :placeholder="$t('Invoice recipient')"
                        type="text"
                        v-model="invoiceRecipient"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="companyAddressLabel"
                      :label="$t('Company address:')"
                      label-for="companyAddress"
                    >
                      <b-form-input
                        id="companyAddress"
                        :placeholder="$t('Company address')"
                        type="text"
                        v-model="companyAddress"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-row>
                      <b-col class="col-3">
                    <b-form-group
                      id="companyZipLabel"
                      :label="$t('Company Zip:')"
                      label-for="companyZip"
                    >
                      <b-form-input
                        id="companyZip"
                        :placeholder="$t('Company Zip')"
                        type="text"
                        v-model="companyZip"
                      ></b-form-input>
                    </b-form-group>
                        </b-col>
                       <b-col class="col-9">
                    <b-form-group
                      id="companyCityLabel"
                      :label="$t('Company City:')"
                      label-for="companyCity"
                    >
                      <b-form-input
                        id="companyCity"
                        :placeholder="$t('Company City')"
                        type="text"
                        v-model="companyCity"
                      ></b-form-input>
                    </b-form-group>
                         </b-col>
                      </b-form-row>
                         <b-form-group
                      id="companyCountryLabel"
                      :label="$t('Company country:')"
                      label-for="companyCountry"
                    >
                    </b-form-group>
                    <b-form-group>
                      <b-form-input
                        id="companyCountry"
                        :placeholder="$t('Company country')"
                        type="text"
                        v-model="companyCountry"
                      ></b-form-input>
                    </b-form-group>
                  </b-card>

                  <div class="" v-if="$v.$error">
                    <small class=" form-text text-danger" v-if="!$v.teamName.required">{{$t('Team name is required')}}</small>
                  </div>
                </b-col>
              </b-row>

              <b-form-checkbox
                class="small m-1"
                id="checkbox-2"
                name="checkbox-2"
                unchecked-value="false"
                v-model="tos_accept"
                value="true"
              >
                {{$t('I accept the details of the')}}
                <router-link :to="'/contracts/Einzelvertrag%20gender%20app.pdf'" target="_blank">{{$t('Individual contract')}}</router-link>, <router-link :to="'/contracts/Datenverarbeitungsvertrag%20gender%20app.pdf'" target="_blank">{{$t('Data processing contract')}}</router-link>
                {{ $t('and the') }} <router-link :to="'/contracts/AGB%20gender%20app.pdf'" target="_blank">{{$t('AGBs')}}</router-link>
              </b-form-checkbox>

              <small class="form-text text-danger"
                     v-if="!$v.tos_accept.required">{{ $t('You must accept TOS') }}
              </small>
              <div v-if="error">
                <b-alert show variant="info">
                  {{error}}
                </b-alert>
              </div>

              <div v-if="success">
                <b-alert show variant="success">
                  {{success}}
                </b-alert>
              </div>

              <div class="mt-3">
                <b-button class="mr-3" type="submit" variant="primary">{{$t('Create Team')}}</b-button>
              </div>
            </b-form>
          </b-card>
        </b-col>
        <!--register modal-->
      <div>
      <b-modal hide-footer ref="registerModal" v-bind:title="$t('you are not logged in')">
        <div class="d-block text-center">
          <small class="text-muted">{{$t('Team creation only works while logged in')}}</small>
          <h3 class="pt-4">{{$t('wanna create an account?')}}</h3>
        </div>
        <router-link :to="{name: 'auth.register'}" class="mt-2 btn btn-outline-success btn-block">{{$t("Go to register")}}</router-link>
        <router-link :to="{name: 'auth.login'}" class="mt-2 btn btn-outline-info btn-block">{{$t("Go to login")}}</router-link>
        <b-button block @click="hideRegisterModal" class="mt-2 btn btn-outline-danger btn-block">{{$t("Cancel")}}</b-button>
      </b-modal>
    </div>
      </b-row>
    </b-container>
  </div>
    <GenderAppFooter/>
  </div>
</template>

<script>
import TopNavBar from '../../components/TopNavBar'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import GenderAppFooter from '../../components/Footer'
import { TeamService } from '@/services/TeamService'
import { AuthService } from '@/services/auth.service'
import { modalMixin } from '@/mixins/modalMixin'

var Vue = require('vue')
export default {
  name: 'CreateTeam',
  data () {
    return {
      teamName: '',
      companyName: '',
      companyAddress: '',
      companyZip: '',
      invoiceRecipient: '',
      companyCity: '',
      companyCountry: '',
      tos_accept: false,
      show: true,
      error: '',
      success: ''
    }
  },
  mixins: [validationMixin, modalMixin],
  validations: {
    teamName: {
      required
    },
    tos_accept: {
      required
    }
  },
  computed:
    {
      isTeamAdmin () {
        const isAdminUser = Vue.default.cookie.get('is_team_admin')
        return isAdminUser && isAdminUser.toLowerCase() === 'true'
      }
    },
  methods: {
    onSubmit (evt) {
      if (!this.isLoggedIn()) {
        this.showRegistrationModal()
        return
      }
      const instance = this
      evt.preventDefault()
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.error = 'Please check above error(s).'
      } else {
        if (this.tos_accept === 'false') {
          this.error = this.$t('Please accept TOS.')
          return false
        }
        const data = {
          name: this.teamName,
          plan: this.$route.query.plan || 'company',
          companyName: this.companyName,
          companyAddress: this.companyAddress,
          companyZip: this.companyZip,
          invoiceRecipient: this.invoiceRecipient,
          companyCity: this.companyCity,
          companyCountry: this.companyCountry
        }
        TeamService.createTeam(data)
          .then((response) => {
            console.log(response)
            if (response.data.status_message) {
              this.error = response.data.status_message
            }
            // setting the Team Admin Flag in the backend is handled by createTeam.
            AuthService.setTeamAdmin(true)
            instance.$router.push('/team-dashboard')
          })
          .catch((error) => {
            console.log(error)
            this.error = error.message.msg
            this.success = ''
          })
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.email = ''
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    showRegistrationModal () {
      this.$refs.registerModal.show()
    },
    hideRegistrationModal () {
      this.$refs.registerModal.hide()
    }
  },
  mounted () {
    if (!this.isLoggedIn()) {
      this.showRegistrationModal()
    }
  },
  watch: {
    teamName: function (val) {
      if (!this.isLoggedIn()) {
        this.showRegistrationModal()
      }
    }
  },
  components: { TopNavBar, GenderAppFooter }
}
</script>

<style scoped>

</style>
