import { BaseService } from './base.service'
import { Http } from './http.init'
import { API_URL } from '../env'
import axios from 'axios'
import { ErrorWrapper, ResponseWrapper } from './utils'

var Vue = require('vue')

export class TeamService extends BaseService {
  static get entity () {
    return 'team'
  }

  static request (status = { auth: true }) {
    return new Http(status)
  }

  static toggleAdmin (userId) {
    const url = `${API_URL}/team/team-admin/${userId}/`
    const headersConfig = this.request().request
    return new Promise((resolve, reject) => {
      axios.put(url, {}, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static removeAccount (userId) {
    const url = `${API_URL}/team/remove-account/${userId}/`
    const headersConfig = this.request().request
    return new Promise((resolve, reject) => {
      axios.put(url, {}, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static getTeamMembers (username) {
    const url = `${API_URL}/team/team-members/`
    const authToken = Vue.default.cookie.get('token')
    let headersConfig = {}
    if (authToken) {
      headersConfig = this.request().request
    }
    return new Promise((resolve, reject) => {
      axios.get(url, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static fetchTeamAddInLoginTablePage (pageNumber) {
    const pageSize = 5
    const url = `${API_URL}/team/team-addin-login-table/?limit=${pageSize}&offset=${(pageNumber - 1) * pageSize}`
    const authToken = Vue.default.cookie.get('token')
    let headersConfig = {}
    if (authToken) {
      headersConfig = this.request().request
    }
    return new Promise((resolve, reject) => {
      axios.get(url, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static fetchTeamAddInSearchTablePage (pageNumber) {
    const pageSize = 5
    const url = `${API_URL}/team/team-addin-search-table/?limit=${pageSize}&offset=${(pageNumber - 1) * pageSize}`
    const authToken = Vue.default.cookie.get('token')
    let headersConfig = {}
    if (authToken) {
      headersConfig = this.request().request
    }
    return new Promise((resolve, reject) => {
      axios.get(url, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static fetchTeamAddInReplacementTablePage (pageNumber) {
    const pageSize = 5
    const url = `${API_URL}/team/team-addin-replacement-table/?limit=${pageSize}&offset=${(pageNumber - 1) * pageSize}`
    const authToken = Vue.default.cookie.get('token')
    let headersConfig = {}
    if (authToken) {
      headersConfig = this.request().request
    }
    return new Promise((resolve, reject) => {
      axios.get(url, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static fetchTeamAddInLoginStats () {
    const url = `${API_URL}/team/team-addin-login-stats/`
    const authToken = Vue.default.cookie.get('token')
    let headersConfig = {}
    if (authToken) {
      headersConfig = this.request().request
    }
    return new Promise((resolve, reject) => {
      axios.get(url, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static updatePlaceholder (username, newPlaceholder) {
    const url = `${API_URL}/auth/shortform-placeholder/${newPlaceholder}/?username=${username}`
    const authToken = Vue.default.cookie.get('token')
    let headersConfig = {}
    if (authToken) {
      headersConfig = this.request().request
    }
    return new Promise((resolve, reject) => {
      axios.get(url, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static createTeam (data) {
    const url = `${API_URL}/team/create-team/`
    const authToken = Vue.default.cookie.get('token')
    let headersConfig = {}
    if (authToken) {
      headersConfig = this.request().request
    }
    return new Promise((resolve, reject) => {
      axios.post(url, data, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static validateMemberEmail (data) {
    const url = `${API_URL}/team/validate-member-email/`
    const authToken = Vue.default.cookie.get('token')
    let headersConfig = {}
    if (authToken) {
      headersConfig = this.request().request
    }
    return new Promise((resolve, reject) => {
      axios.post(url, data, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static assignExistingMemberToTeam (data) {
    const url = `${API_URL}/team/assign-existing-member-to-team/`
    const authToken = Vue.default.cookie.get('token')
    let headersConfig = {}
    if (authToken) {
      headersConfig = this.request().request
    }
    return new Promise((resolve, reject) => {
      axios.post(url, data, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static assignCreatedByAdmin (data) {
    const url = `${API_URL}/team/assign-created-by-admin/`
    const authToken = Vue.default.cookie.get('token')
    let headersConfig = {}
    if (authToken) {
      headersConfig = this.request().request
    }
    return new Promise((resolve, reject) => {
      axios.post(url, data, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }
}
